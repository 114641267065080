// import { graphql, Link } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import styled from "styled-components";
import CustomMapContainer from "../components/CustomMap";
import Footer from "../components/footer.js";
import Header from "../components/Header";
import Layout from "../components/layout";

const Input = styled.input`
  width: 280px;
  padding: 10px;
  border: 0px;
  border-radius: 2px;
  background-color: #f7f7f7;
`;

const DetailsContainer = styled.div`
  grid-column: 8/13;

  padding-top: 0px;
  h1 {
    margin-top: 0;
    padding-top: 0;
  }

  .w-select {
    width: 280px;
    text-transform: none;
    display: inline-block;
  }

  .form-inline {
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  .rotate {
    height: 38px;
    margin-left: 4px;
    width: 38px;
    padding: 6px;
    background: none;
  }

  .createprint {
    height: 48px;
    line-height: 48px;
    padding: 0 20px;
    background-color: #000;
    color: #fff;
    margin-top: 40px;
    width: 240px;
    border-radius: 2px;
  }

  .disabled {
    background-color: #666;
    color: #ddd;
  }
`;

const ZoomError = styled.p`
  color: red;
  width: 320px;
`;

const CreatorTipsList = styled.ol`
  padding-left: 14px;
  margin-bottom: 24px;
`;

const SampleImagesContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: auto;
`;

const SampleImageContainer = styled.div`
  display: inline-block;
  width: 33%;
  padding: 1vw;
`;

const DemoVideo = styled.video`
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 24px;
`;

const OneAtlasName = styled.b`
  font-size: 24px;
  margin: 6px;
  color: rgb(0, 31, 91);
`;

const SectionHeader = styled.h4`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 40px;
`;

const CopyrightInformation = styled.p`
  text-align: center;
  margin-top: 24px;
`;

const MobileLineBreak = styled.br`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
    margin: 10px;
    content: "";
  }
`;

const queryParams = {
  // country: "us"
};

const MAP_SIZES = ["12x15", "15x15", "20x25", "20x20", "24x30", "30x30"];

const PRICING = {
  "12x15": 125,
  "15x12": 125,
  "15x15": 150,
  "20x25": 250,
  "25x20": 250,
  "20x20": 200,
  "24x30": 300,
  "30x24": 300,
  "30x30": 350,
};

const NEW_MAP_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? `http://localhost:8000/api/public/maps`
    : `https://daily-overview.herokuapp.com/api/public/maps`;

const SampleImage = ({ src, label }) => {
  return (
    <SampleImageContainer>
      <img src={src} loading="lazy" />
      <p className="caption form-heading" style={{ textAlign: "center" }}>
        {label}
      </p>
    </SampleImageContainer>
  );
};

class CustomOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGeometry: undefined,
      rotated: false,
      printWidth: 12,
      printHeight: 15,
      form: {
        name: "",
        email: "",
      },

      // Meta
      isValidZoom: true,

      // Saving
      isSaving: false,
      didError: false,
      error: undefined,
    };
  }

  updateGeometry = (geometry) => {
    this.setState({
      selectedGeometry: geometry,
    });
  };

  updateZoomValidity = (isValid) => {
    this.setState({
      isValidZoom: isValid,
    });
  };

  updateField = (event) => {
    const {
      target: { value, id: key },
    } = event;
    let updatedForm = { ...this.state.form };
    updatedForm[key] = value;
    this.setState({
      form: updatedForm,
    });
  };

  updateSize = (event) => {
    const {
      target: { value },
    } = event;
    const [width, height] = value.split("x");
    this.setState({
      printWidth: parseInt(width),
      printHeight: parseInt(height),
    });
  };

  changeOrientation = (event) => {
    event.preventDefault();
    this.setState({
      rotated: !this.state.rotated,
      printHeight: this.state.printWidth,
      printWidth: this.state.printHeight,
    });
  };

  submitCustomOrder = (e) => {
    e.preventDefault();
    const {
      form: { name, email },
      printWidth,
      printHeight,
      selectedGeometry,
    } = this.state;

    this.setState({
      isSaving: true,
    });
    const payload = {
      map: {
        name,
        email,
        print_width: printWidth,
        print_height: printHeight,
        ul_lat: selectedGeometry.ll.lat,
        ul_long: selectedGeometry.ll.long,
        ur_lat: selectedGeometry.ur.lat,
        ur_long: selectedGeometry.ur.long,
        ll_lat: selectedGeometry.ll.lat,
        ll_long: selectedGeometry.ll.long,
        lr_lat: selectedGeometry.lr.lat,
        lr_long: selectedGeometry.lr.long,
      },
    };
    console.log(payload);

    fetch(NEW_MAP_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        const { success, id, error } = data;
        if (success) {
          this.setState({ isSaving: false });
          window.location.href = `/custom-product?productId=${id}`;
        } else {
          this.setState({
            isSaving: false,
            didError: true,
            error,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isSaving: false,
          didError: true,
          error,
        });
      });
  };

  render() {
    const {
      rotated,
      printWidth,
      printHeight,
      form: { name, email },
      selectedGeometry,
      isSaving,
      isValidZoom,
    } = this.state;
    const canSubmit = selectedGeometry && name && email;
    const submitDisabled = isSaving || !canSubmit;

    const mapSizeOptions = MAP_SIZES.map((s) => {
      let val = rotated
        ? s
            .split("x")
            .reverse()
            .join("x")
        : s;
      return (
        <option key={val} value={val}>
          {val}" - ${PRICING[val]}
        </option>
      );
    });

    return (
      <Layout>
        <HelmetDatoCms>
          <title>Overview Custom Print Creator</title>
          <meta property="og:title" content="Overview Custom Print Creator" />
          <meta
            property="og:image"
            content={require("../images/custom-order/Icon.jpg")}
          />
        </HelmetDatoCms>

        <Header content_color="black" />

        <div className="custom-container">
          <div className="custom-left">
            <div className="new-map-square">
              <div className="new-map-square-content">
                <CustomMapContainer
                  updateGeometry={this.updateGeometry}
                  updateZoomValidity={this.updateZoomValidity}
                  print={{ width: printWidth, height: printHeight }}
                />
              </div>
            </div>

            <div className="new-map-airbus-logo">
              <p>
                Custom print creator powered by <MobileLineBreak />
                <a href="https://oneatlas.airbus.com" target="_blank">
                  <OneAtlasName>OneAtlas</OneAtlasName>
                </a>{" "}
                from
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/5/5d/Airbus_Logo_2017.svg"
                  style={{ marginTop: -10, width: 110, marginLeft: 8 }}
                />
              </p>
            </div>
          </div>
          <div className="custom-right">
            <DetailsContainer>
              <h1 className="sub-headline">Custom Print Creator</h1>
              <form method="POST" onSubmit={this.submitCustomOrder}>
                <label className="caption form-heading">
                  Preferred Orientation
                </label>

                <div className="form-inline">
                  <select
                    className="caption w-select"
                    name="Preferred Orientation"
                    onChange={this.updateSize}
                    value={`${printWidth}x${printHeight}`}
                  >
                    {mapSizeOptions}
                  </select>
                  <button
                    type="button"
                    className="rotate"
                    onClick={this.changeOrientation}
                  >
                    <img src="https://cdn3.iconfinder.com/data/icons/virtual-notebook/16/button_rotate-512.png" />
                  </button>
                </div>
                {!isValidZoom && (
                  <ZoomError>
                    Based on your selected area and size, we may not be able to
                    print in high quality.
                    <br />
                    Zoom out to improve resolution.
                  </ZoomError>
                )}

                <label className="caption form-heading">Creator Tips</label>
                <CreatorTipsList>
                  <li>Use the search bar to find a general area of interest</li>
                  <li>Click and drag the map to crop</li>
                  <li>Zoom by scrolling up / down or by pinching</li>
                  {/* <li>
                    Hold “Ctrl” on keyboard while composing to rotate the image
                  </li> */}
                </CreatorTipsList>

                <label className="caption form-heading">Your Name</label>
                <Input
                  type="text"
                  name="Name: "
                  onChange={this.updateField}
                  id="name"
                  placeholder="Your Name"
                  value={this.state.form.name}
                />
                <label className="caption form-heading">Your Email</label>
                <Input
                  type="text"
                  name="Email: "
                  placeholder="Your Email"
                  onChange={this.updateField}
                  id="email"
                  value={this.state.form.email}
                />

                <br />
                <input
                  type="submit"
                  className={`createprint ${submitDisabled ? "disabled" : ""}`}
                  value={
                    !isSaving ? "Submit Custom Print Request" : "Submitting..."
                  }
                  disabled={submitDisabled}
                />
              </form>
            </DetailsContainer>
          </div>
        </div>

        <SampleImagesContainer>
          <SectionHeader className="sub-headline">
            Check out some of our favorite requests to date
          </SectionHeader>
          <SampleImage
            src={require("../images/custom-order/Barcelona.jpg")}
            label="Barcelona"
          />
          <SampleImage
            src={require("../images/custom-order/Crater Laker.jpg")}
            label="Crater Laker"
          />
          <SampleImage
            src={require("../images/custom-order/Grand Canyon.jpg")}
            label="Grand Canyon"
          />
          <SampleImage
            src={require("../images/custom-order/Gobi Desert.jpg")}
            label="Gobi Desert"
          />
          <SampleImage
            src={require("../images/custom-order/Japan.jpg")}
            label="Japan"
          />
          <SampleImage
            src={require("../images/custom-order/Texas.jpg")}
            label="Texas"
          />

          <CopyrightInformation>
            Copyright for all imagery from Custom Print Creator:
            <br />
            <br />
            Pléiades — © CNES 2020, Distribution Airbus DS
            <br />
            SPOT 6 & 7 — © Airbus DS (2020)
            <br />
            PlanetSATGlobal — © PlanetObserver (contains Copernicus data)
            <br />
          </CopyrightInformation>
        </SampleImagesContainer>

        <SampleImagesContainer>
          <SectionHeader className="sub-headline">
            Watch a demo of the tool in action
          </SectionHeader>
          <DemoVideo
            src={require("../images/custom-order/Paris Demo.mp4")}
            controls="auto"
            loading="lazy"
            muted
          />
        </SampleImagesContainer>

        <Footer />
      </Layout>
    );
  }
}

export default CustomOrderPage;
