export const metersBetweenPoints = (lat1, lon1, lat2, lon2) => {
  // generally used geo measurement function
  var R = 6378.137; // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  return d * 1000; // meters
};

export const dimensionsToMeters = (lat1, lon1, lat2, lon2) => {
  const height = metersBetweenPoints(lat1, lon1, lat2, lon1);
  const width = metersBetweenPoints(lat1, lon1, lat1, lon2);

  return {
    width,
    height,
  };
};

// Resolution is number of meters per pixel
export const metersToPixels = (meters, resolution) => {
  return meters / resolution;
};
