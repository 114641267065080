import "mapbox-gl/dist/mapbox-gl.css";
import React, { Component } from "react";
import ReactMapGL, { Layer } from "react-map-gl";
import Geocoder from "react-mapbox-gl-geocoder";
import styled from "styled-components";
import { dimensionsToMeters, metersToPixels } from "../../util/geometry";

const SearchBox = styled(Geocoder)`
  top: 15px;
  left: 15px;
  z-index: 1;
  max-width: 300px;
  background: #fff;
  padding: 5px;
  position: absolute;
  border-radius: 2px;
  input {
    border: 0px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAACU0lEQVR4Xu2aQYQbYRTHm24vw1CGEkpPpaRKqysrp63ULlWWkFMP1bKn6mHppedeeiqp9lSih0q1tpftqbo6WkJsNbosuYZlWUroKZRIfyWHz5+kk0wyj/UNP8+7PPnlveebmSQ3HA5PnYTrNJwUES/iRbyIF/EiXsSLnJlGIJfLlQgbUIYrEEAfDiCGj9CaVoDbpGxEECgQarAGegVQHPEYdmELOtajpRJVwp5ITGIN9qBqKaISFcI2hCLQhVcj6nAoMiFsQ8VqR3ScGiKwDw+Y6ZYI/wtleAEFR6gBy9Cx7EgNAif/ACuOhBLDMnyS/amZjRbdWJWdOIJNJP6IgNKHu9CTnVm16sgdye8j8TthzV+wpfVMROQbPEBid8q6b6UrZh254CTtGeoOoCX1shVhP0JZ8t6MtXuy9GHWHenDwMnPzSgSSYf6mYqwDwNCN+V8L0FJDtCBxY58d+ebcStPKVKBSOtZiNQlf4ZMkFDiLDzVeiYijFdMaDoiV+EdMmECiR246Eg0Iba8RXkE7km+AT+QuT1Gogo/QXcqgvyiRSa++6UD9wivx5zeLTiG81CSnVA6cBOOx0zAQkRU5iGhBkuQ4hIZEVn4gxWiLwnrsJ9Q4NuYQ7QAXyCf6Y7o8sM1klvwHNrOhz2EJjyBFbgB61nLpPl9RB+slOvwGaJJY5bBaKWm/Z/OfJXOpBcxkrkkMulFrGUYzfx8ROxlduYjYi9TpCtRehF7mTecV73UIkYyR6P8PWzO5QWdkcxliKA7p3st/4cBL+JFvIgX8SJe5C+JA7GR8loW+wAAAABJRU5ErkJggg==");
    background-size: 20px auto;
    background-position: center left;
    background-repeat: no-repeat;
    font: 400 12px am, sans-serif;
    text-transform: uppercase;
    padding-left: 24px;
  }
  .react-geocoder-item {
    cursor: pointer;
    font: 400 12px am, sans-serif;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .react-geocoder-item:hover {
    text-decoration: underline;
  }
`;

const LocationButton = styled("button")`
  top: 15px;
  right: 15px;
  z-index: 1;
  max-width: 300px;
  background: #fff;
  padding: 5px;
  position: absolute;
  border-radius: 2px;
`;

const queryParams = {
  // country: "us"
};

const DEFAULT_VIEWPORT = {
  width: 800,
  height: 600,
  longitude: -122.27224924549643,
  latitude: 37.60315730370322,
  zoom: 9.2,
  bearing: 0,
};

// Basemap API Key
let apiKey =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI2YzdlZGI4NC1iNDM1LTRiZmEtOTk0Yi1iYWU1YjhhYzBiNzEiLCJhdWQiOiJodHRwczovL29uZWF0bGFzLmRhdGFkb29ycy5uZXQvaWQvcmVzb3VyY2VzIiwiaWRwIjoiVUNBX0FERlMiLCJhdXRoX3RpbWUiOjE2Mzc3NDUyOTcsImFtciI6ImV4dGVybmFsIiwic2NvcGUiOlsib3BlbmlkIiwicHJvZmlsZSIsImVtYWlsIiwicm9sZXMiLCJyZWFkIiwid3JpdGUiXSwiaXNzIjoiaHR0cHM6Ly9vbmVhdGxhcy5kYXRhZG9vcnMubmV0L2lkIiwiZXhwIjoxNjY5MjgxMjk3LCJjbGllbnRfaWQiOiJwdGVzdF9leGFtcGxlXzEifQ.eSb6afm-Bq3eKvHChHZRhcvlI2mpRX93VjzgDMDXIlexvuYHXawUMJM7Av8FnBLvIWUS3m9duVAoD0MyyjCQcEH6KmQcw7lJx9uTaq-apGUVV0hG-BDvBCSX2AHOoMVvA44Fdk9X32Oq2-pO_d9omMIVpp8xJXnG7v2gHIkkHh6z41WF4G1v9LNHWlMWwP-iu1SBGughRq7wLm_RsI_YkVyfNDXHVh0qcqlJvbwSMoYPTSdVRlLGNDQLF8Rf7JL2bdD3dIWutC9PcltYuZVtwh4SJB40eOEeY6axtcL4vZ-7JYegeu8JsQpxQX1uQbmnKc7QYA6KEKl207RMLFxJ0A";

class CustomMapContainer extends Component {
  state = {
    // map
    viewport: DEFAULT_VIEWPORT,
    // editor
    selectedMode: null,
    selectedFeatureIndex: null,
    selectable: false,

    // Meta
    isValidZoom: true,

    // Current location
    currentLocationLoading: false,

    features: [],
  };

  componentWillMount() {
    this._editorRef = null;
  }

  _editorRef;
  _mapRef = React.createRef();

  /**
   * Update the map's viewbox.
   *
   * Note: `transitionDuration` is 1 on Desktop when zooming, 0 for panning. On mobile, both are 0.
   *
   * @param {Mapbox Viewport} viewport
   */
  _updateViewport = (viewport) => {
    // Update parent component if need be
    if (this._mapRef && this._mapRef.current && this._mapRef.current.getMap) {
      const map = this._mapRef.current.getMap();
      const {
        _ne: { lng: maxLong, lat: maxLat },
        _sw: { lng: minLong, lat: minLat },
      } = map.getBounds();

      const w = viewport.width;
      const h = viewport.height;
      const cUL = map.unproject([0, 0]).toArray();
      const cUR = map.unproject([w, 0]).toArray();
      const cLR = map.unproject([w, h]).toArray();
      const cLL = map.unproject([0, h]).toArray();

      const geometry = {
        ul: {
          lat: cUL[1],
          long: cUL[0],
        },
        ur: {
          lat: cUR[1],
          long: cUR[0],
        },
        ll: {
          lat: cLL[1],
          long: cLL[0],
        },
        lr: {
          lat: cLR[1],
          long: cLR[0],
        },
        bearing: viewport.bearing || 0,
        zoom: viewport.zoom,
      };

      // Might have to update this calculation to take into account rotation
      const { width: widthMeters, height: heightMeters } = dimensionsToMeters(
        minLat,
        minLong,
        maxLat,
        maxLong
      );

      const DPI = 250;
      const pixelWidth = metersToPixels(widthMeters, 1);
      const pixelHeight = metersToPixels(heightMeters, 1);
      const isZoomingIn = viewport.zoom > this.state.viewport.zoom;

      const regionTooClose =
        pixelWidth / DPI < this.props.print.width ||
        pixelHeight / DPI < this.props.print.height;

      this.props.updateZoomValidity(!regionTooClose);

      // Only bound when zooming in
      if (!regionTooClose || !isZoomingIn) {
        this.props.updateGeometry(geometry);
        this.setState({ viewport });
      }
    }
  };

  goToCurrentLocation = () => {
    const { viewport } = this.state;
    this.setState({
      currentLocationLoading: true,
    });

    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const updatedViewport = {
        ...viewport,
        latitude,
        longitude,
      };
      this.setState({
        viewport: updatedViewport,
        currentLocationLoading: false,
      });
    });
  };

  render() {
    const { print } = this.props;
    const { viewport, currentLocationLoading } = this.state;

    // Requires the parent to be square
    let widthScaled = 100;
    let heightScaled = 100;
    if (print.width > print.height) {
      heightScaled = (100 * print.height) / print.width;
    } else {
      widthScaled = (100 * print.width) / print.height;
    }

    return (
      <div
        style={{
          width: `${widthScaled}%`,
          height: `${heightScaled}%`,
        }}
        className="custom-map-frame"
      >
        <SearchBox
          mapboxApiAccessToken="pk.eyJ1IjoiZ3JlZ2NvcmJ5IiwiYSI6ImNrMDA5bXlxejAxZGYzbW40cXlpaGxwOTQifQ.208zF_2QfWCxkI6qrj5dnw"
          onSelected={this._updateViewport}
          viewport={viewport}
          hideOnSelect={true}
          queryParams={queryParams}
        />
        <LocationButton onClick={this.goToCurrentLocation}>
          <img
            className={currentLocationLoading ? "loading-blink" : ""}
            src="https://static.thenounproject.com/png/2819186-200.png"
            width={24}
            height={24}
          />
        </LocationButton>
        <ReactMapGL
          {...viewport}
          pitch={0}
          bearing={0}
          width={`100%`}
          height={`100%`}
          ref={this._mapRef}
          mapboxApiAccessToken="pk.eyJ1IjoiZ3JlZ2NvcmJ5IiwiYSI6ImNrMDA5bXlxejAxZGYzbW40cXlpaGxwOTQifQ.208zF_2QfWCxkI6qrj5dnw"
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          onViewportChange={this._updateViewport}
          transformRequest={(url, resourceType) => {
            if (
              resourceType == "Tile" &&
              url.startsWith("https://view.geoapi-airbusds.com")
            ) {
              return {
                url: url,
                headers: { Authorization: "Bearer " + apiKey },
              };
            }
          }}
        >
          <Layer
            id="Basemap WMS"
            type="raster"
            source={{
              type: "raster",
              tiles: [
                "https://view.geoapi-airbusds.com/api/v1/map/imagery.wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256",
              ],
              tileSize: 200,
            }}
            paint={{}}
          />
        </ReactMapGL>
      </div>
    );
  }
}

// CustomMapContainer.prototype = {
//   print: {
//     width: PropTypes.number,
//     height: PropTypes.number,
//   },
//   updateGeometry: PropTypes.func,
// };

export default CustomMapContainer;
